<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="800px" >

      <v-card>

        <v-card-title class="text-body-1">Está seguro de que quiere eliminar este elemento?</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn color="error" dark @click="deleteItemConfirm" :loading="isSaving">
            Eliminar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>
</template>

<script>
export default {
  name: 'DeleteItemDialog',
  props: {
    value: Boolean,
    deleteURL: {
      type: String,
      required: true,
    },
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  data() {
    return {
      //state
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
    }
  },
  methods: {
    async deleteItemConfirm() {
      try {
        
        this.isSaving = true

        await this.axios.delete(this.deleteURL)

        this.show = false
        this.isSaving = false

        this.$emit('deleted')

        this.snackcolor = 'success'
        this.snacktext = 'El elemento ha sido eliminado'
        this.isSnackbar = true

      } catch (e) {

        console.error(e)
        this.snackcolor = 'error'
        this.snacktext = 'Ha ocurrido un error al eliminar el elemento'
        this.isSnackbar = true

      } finally {
        this.isSaving = false
      }
    },
  }
}
</script>