<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="800px" >

      <v-card>
        <v-card-title class="blue darken-2 white--text mb-4">
          <span>Editar Programación</span>
        </v-card-title>

        <v-card-text>
         <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editProgramming.title"
                  label="Título*"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editProgramming.date"
                  type="date"
                  label="Fecha*"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editProgramming.time"
                  type="time"
                  label="Hora"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editProgramming.endDate"
                  type="date"
                  label="Fecha de finalización (opcional)"
                  hint="Si este evento no tiene un rango de fechas, deje este campo vacío"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editProgramming.endTime"
                  type="time"
                  label="Hora de Finalización (opcional)"
                  hint="Si este evento no tiene un rango de fechas, deje este campo vacío"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-switch
                  v-model="editProgramming.isOnline"
                  color="success"
                  label="Es evento Online?"
                  hide-details
                />
                <v-switch
                  v-model="editProgramming.isUnconfirmed"
                  color="success"
                  label="Evento sin confirmar?"
                  hide-details
                />
                <v-switch
                  v-model="editProgramming.isVisible"
                  color="success"
                  label="Mostrar evento?"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="saveEditProgramming()" :disabled="isSaving" :loading="isSaving">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>
</template>

<script>
import { getYYYYMMDD, getHHMM } from '@/libs/dateHelpers'

export default {
  name: "EditProgrammingDialog",
  props:{
    value: Boolean,
    fetchId: String,
  },
  data() {
    return {
      //state
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
      //form
      editProgramming: {
        title: '',
        date: null,
        time: null,
        endDate: null,
        endTime: null,
        isOnline: false,
        isUnconfirmed: true,
        isVisible: true,
      }
    }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  watch: {
    show() {
      if(this.show) this.loadProgramming()
    }
  },
  methods: {
    async loadProgramming() {
      try {

        if(!this.fetchId) throw 'Missing id, cannot load info'

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/events/id/${this.fetchId}`)

        const dateWithoutTZ = new Date(response.data.data.date.replace('Z', ''))
        const endDateWithoutTZ = (response.data.data.endDate) ? new Date(response.data.data.endDate.replace('Z', '')) : null

        //parse data as YYYYMMDD
        const date = getYYYYMMDD(dateWithoutTZ)
        const time = getHHMM(dateWithoutTZ)

        let endDate = null
        let endTime = null

        if(endDateWithoutTZ !== null) {
          endDate = getYYYYMMDD(endDateWithoutTZ)
          endTime = getHHMM(endDateWithoutTZ)
        }

        if(response.data.data) {
          this.editProgramming = { ...this.editProgramming, ...response.data.data, ...{ date: date, time: time, endDate: endDate, endTime: endTime} }
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al cargar la programación'
        this.isSnackbar = true
        console.error(e)
      }
    },
    async saveEditProgramming() {
      try {

        if(!this.fetchId) return console.error('Missing id, cannot edit')

        if(!this.editProgramming.title || !this.editProgramming.date) return this.invalidFormNotification()

        this.isSaving = true

        await this.axios.put(`${process.env.VUE_APP_APIURL}/events/id/${this.fetchId}`, this.editProgramming)

        this.$emit('saved')

        this.snackcolor = 'success'
        this.snacktext = 'Programación modificada'
        this.isSnackbar = true

        this.show = false

        this.editProgramming = {
          title: '',
          date: null,
          time: null,
          endDate: null,
          endTime: null,
          isOnline: false,
          isUnconfirmed: true,
          isVisible: true,
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al modificar la programación'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isSaving = false
      }
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete los campos requeridos'
      this.isSnackbar = true
    },
    closeDialog() { this.show = false }
  }
}
</script>