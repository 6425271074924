<template>
  <v-container>
    
    <v-row>
      <v-col cols="12">

        <v-row class="mb-3">
          <v-col>
            <v-btn to="/admin" color="secondary" small>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
        </v-row>

        <v-card>
          <v-card-title class="pb-0">

            <v-container>

              <v-row>
                <v-col class="mb-2 py-0">
                  <p class="text-h5 mb-0">
                    <v-avatar color="orange" size="38"><v-icon dark>mdi-calendar</v-icon></v-avatar>
                    Gestión de Programación
                  </p>
                </v-col>
              </v-row>

              <v-row class="mt-2 py-0">
                <v-col cols="8">
                  <v-text-field v-model="search" label="Buscar" prepend-inner-icon="mdi-magnify" single-line hide-details clearable filled rounded dense class="searchinput" />                </v-col>
                <v-col cols="4">
                  <v-btn block small color="success" class="mt-1" @click="addDialog = true" >
                    Nueva Programación
                  </v-btn>
                </v-col>

              </v-row>

            </v-container>
            
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="events"
            :search="search"
            :loading="isLoading"
          >

            <template v-slot:item.date="{ item }">
              {{getYYYYMMDD(item.date)}} {{getHHMM(item.date)}}hrs
            </template>

            <template v-slot:item.isOnline="{ item }">
              <v-chip :color="(item.isOnline) ? 'blue' : 'green'" small dark >
                {{(item.isOnline) ? 'Online' : 'Presencial'}}
              </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="openEditDialog(item._id)">mdi-pencil</v-icon>
              <v-icon small class="mr-2" @click="openDeleteDialog(item._id)">mdi-close</v-icon>
            </template>

          </v-data-table>

        </v-card>

      </v-col>
    </v-row>

    <!-- CRUD Dialogs -->
    <CreateProgrammingDialog v-model="addDialog" @saved="loadProgram()" />
    <EditProgrammingDialog v-model="editDialog" :fetchId="editFetchId" @saved="loadProgram()" />
    <DeleteItemDialog v-model="deleteDialog" :deleteURL="deleteURL" @deleted="loadProgram()" />

  </v-container>
</template>

<script>
import CreateProgrammingDialog from "@/components/admin/CreateProgrammingDialog"
import EditProgrammingDialog from "@/components/admin/EditProgrammingDialog"
import DeleteItemDialog from "@/components/admin/DeleteItemDialog"

import { getYYYYMMDD, getHHMM } from '@/libs/dateHelpers'

export default {
  name: "AdminProgramming",
  components: {
    CreateProgrammingDialog,
    EditProgrammingDialog,
    DeleteItemDialog,
  },
  data() {
    return {
      //state
      search: '',
      isLoading: true,
      addDialog: false,
      editDialog: false,
      deleteDialog: false,
      editFetchId: null,
      deleteURL: '',
      //definitions
      headers: [
        { text: 'Fecha', value: 'date' },
        { text: 'Evento', value: 'title' },
        { text: 'Modalidad', value: 'isOnline' },
        { text: 'Transmisión', value: 'streamLocation' },
        { text: 'Acciones', value: 'actions' },
      ],
      events: []
    }
  },
  methods: {
    async loadProgram() {
      try {

        this.isLoading = true

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/events/all`)

        this.events = response.data.data.map(event => {
          return { ...event, date: new Date(event.date.replace('Z', '')) }
        })

      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
    openEditDialog(id) {
      this.editFetchId = id
      this.editDialog = true
    },
    openDeleteDialog(id) {
      this.deleteURL = `${process.env.VUE_APP_APIURL}/events/id/${id}`
      this.deleteDialog = true
    },
  },
  mounted() {
    this.loadProgram()
  },
  beforeCreate() { // make libs available in template
    this.getYYYYMMDD = getYYYYMMDD
    this.getHHMM = getHHMM
  }
}
</script>
