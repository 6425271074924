<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="800px" >

      <v-card>
        <v-card-title class="blue darken-2 white--text mb-4">
          <span>Nueva Programación</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newProgramming.title"
                  label="Título*"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="newProgramming.date"
                  type="date"
                  label="Fecha*"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="newProgramming.time"
                  type="time"
                  label="Hora"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="newProgramming.endDate"
                  type="date"
                  label="Fecha de finalización (opcional)"
                  hint="Si este evento no tiene un rango de fechas, deje este campo vacío"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="newProgramming.endTime"
                  type="time"
                  label="Hora de Finalización (opcional)"
                  hint="Si este evento no tiene un rango de fechas, deje este campo vacío"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-switch
                  v-model="newProgramming.isOnline"
                  color="success"
                  label="Es evento Online?"
                  hide-details
                />
                <v-switch
                  v-model="newProgramming.isUnconfirmed"
                  color="success"
                  label="Evento sin confirmar?"
                  hide-details
                />
                <v-switch
                  v-model="newProgramming.isVisible"
                  color="success"
                  label="Mostrar evento?"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="saveProgramming()" :disabled="isSaving" :loading="isSaving">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>
</template>

<script>

export default {
  name: "CreateProgrammingDialog",
  props:{
    value: Boolean
  },
  data() {
    return {
      //state
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
      //form
      newProgramming: {
        title: '',
        date: null,
        time: null,
        endDate: null,
        endTime: null,
        isOnline: false,
        isUnconfirmed: true,
        isVisible: true,
      }
    }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  methods: {
    async saveProgramming() {
      try {

        if(!this.newProgramming.title || !this.newProgramming.date) return this.invalidFormNotification()

        this.isSaving = true

        const dates = {
          date: `${this.newProgramming.date}T${this.newProgramming.time}:00Z`,
          endDate: this.newProgramming.endDate ? `${this.newProgramming.endDate}T${this.newProgramming.endTime}:00Z` : null
        }

        const sendData = {...this.newProgramming, ...dates}

        await this.axios.post(`${process.env.VUE_APP_APIURL}/events`, sendData)

        this.$emit('saved')

        this.snackcolor = 'success'
        this.snacktext = 'Programación guardada'
        this.isSnackbar = true

        this.show = false

        this.newProgramming = {
          title: '',
          date: null,
          time: null,
          endDate: null,
          endTime: null,
          isOnline: false,
          isUnconfirmed: true,
          isVisible: true,
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al guardar la programación'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isSaving = false
      }
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete los campos requeridos'
      this.isSnackbar = true
    },
    closeDialog() { this.show = false }
  }
}
</script>